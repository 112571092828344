import MainHeader from '../../components/mainHeader/Index.vue'
import MainFooter from '../../components/mainFooter/Index.vue'
import Dict from '../../components/dict/Index.vue'
import {queryJobs,getJobRoles,getJobLocations} from '@/api/job'
import Pagination from '@/components/pagination/Index.vue'
import LoadMore from '@/components/loadMore/Index.vue'
export default {
  components: { MainHeader, MainFooter, Dict, Pagination,LoadMore },
  data(){
    return{
      currentAccount: null,
      topics: [],
      roles: [],
      locations: [],
      jobs:[],
      queryParams:{pageSize:5,pageNo:1},
      total:0,
      no_data: false,
      has_log: 0,
      iscompleted:false,
      selectRoleName: null,
      selectLocationName: null,
      listLoading: false
    }
  },
  created(){
    this.queryJobs()
    this.initDropdown()
  },
  methods: {
    postJob(){
      if(this.currentAccount == null){
        this.$message({
          showClose: true,
          message: 'Please connect wallet first!',
          type: 'error'
        })
        return
      }

      this.$router.push({name:'jobPost'})
    },
    toRouter(path){
      this.$router.push({name:path})
    },
    toJobDetail(jobId){
      this.$router.push({name:'jobDetail',query:{jobId:jobId}})
    },
    changPages(page){
      this.listLoading = true
      this.queryParams.pageNo = page
      this.queryJobs()
    },
    queryJobs(){
      const that = this
      queryJobs(that.queryParams).then((resp) => {
        // that.jobs = [that.jobs, ...resp.data.list]
        that.jobs.splice(that.jobs.length,0,...resp.data.list);
        that.total = resp.data.total
        if(resp.data.list.length == 0)
          that.iscompleted = true

        that.listLoading = false
      })
    },
    initDropdown(){
      const that = this
      getJobRoles().then((resp)=>{
        that.roles = resp.data
      })
      getJobLocations().then((resp)=>{
        that.locations = resp.data
      })
    },
    handleSelectRole(role){
      this.iscompleted=false
      this.queryParams.pageNo=1
      this.queryParams.pageSize=5
      this.queryParams.role = role.value
      this.selectRoleName = role.name
      this.jobs = []
      this.$refs["jobLoadMore"].pullDown();
    },
    handleSelectLocation(location){
      this.iscompleted=false
      this.queryParams.pageNo=1
      this.queryParams.pageSize=5
      this.queryParams.location = location.value
      this.selectLocationName = location.name
      this.jobs = []
      this.$refs["jobLoadMore"].pullDown();
    }
  }
};